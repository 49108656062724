import { HttpService } from "@common/services/server/http.service";
import {
    IChangePasswordData,
    IGetCurrentUserResponse,
    IGetLoginBannerLocationResponse,
    IGetProjectActivityResponse,
    IGetSSOConfig,
    IGetUsersResponse,
    ILoginData,
    ILoginResponse,
    IPasswordResetData,
    IPasswordResetSubmitData,
    IUserResponse,
} from "@common/services/server/userApi.types";

export const UserApiService = {
    login(data: ILoginData) {
        return HttpService.post<ILoginResponse>("/login", data)
            .then(HttpService.showIfSuccess("Successfully logged in."))
            .catch(HttpService.showIfError("Unknown error occurred while logging in."));
    },
    logout() {
        return HttpService.post<IUserResponse>("/logout")
            .then(HttpService.showIfSuccess("Successfully logged out."))
            .catch(HttpService.showIfError("Unknown error occurred while logging out."));
    },
    getCurrentUser() {
        return HttpService.get<IGetCurrentUserResponse>("/current_user");
    },
    getUsers() {
        return HttpService.get<IGetUsersResponse>("/user").catch(
            HttpService.showIfError("Unknown error occurred while loading users."),
        );
    },
    getProjectActivity() {
        return HttpService.get<IGetProjectActivityResponse>("/project_activity");
    },
    getLoginBannerLocation() {
        return HttpService.get<IGetLoginBannerLocationResponse>("/login_banner_url").catch(
            HttpService.showIfError("Unknown error occurred while loading banner."),
        );
    },
    geMediaBannerConfiguration() {
        return HttpService.get("/logo/carousel_config.txt", { responseType: "text" }).catch(
            HttpService.showIfError("Unknown error occurred while loading configuration."),
        );
    },
    passwordResetSubmit(token: string, data: IPasswordResetSubmitData) {
        return HttpService.post<IUserResponse>(`/password/reset/${token}/`, data);
    },
    passwordReset(data: IPasswordResetData) {
        return HttpService.post<IUserResponse>("/password/reset/", data);
    },
    resetPasswordForUser(userId: number) {
        return HttpService.post<IUserResponse>(`/admin/user/${userId}/reset`);
    },
    changePassword(data: IChangePasswordData) {
        return HttpService.post<IUserResponse>("/password/change", data);
    },
    enterSandbox() {
        return HttpService.post<IUserResponse>("/enter_sandbox").catch(
            HttpService.showIfError("Unknown error occurred while entering into sandbox."),
        );
    },
    exitSandbox() {
        return HttpService.post<IUserResponse>("/exit_sandbox").catch(
            HttpService.showIfError("Unknown error occurred while exiting from sandbox."),
        );
    },
    getSSOConfig() {
        return HttpService.get<IGetSSOConfig>("/sso_config");
    },
};
